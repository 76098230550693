<template>
  <div class="invoice">
    <div class="bg-blue relative pa-10">
      <div class="grid-x grid-padding-x border-bottom">
        <div class="cell small-12 medium-4">
          <div class="bg-blue-important pa-2">
            <img src="../assets/cc_logo.svg" alt="Customised Clothing" />
          </div>
        </div>
        <div class="cell small-12 medium-8">
          <p class="text-white float-right">
            Customised Clothing<br />
            53 Bedford Street South,<br />
            Leicester,<br />
            LE1 3JR<br />
          </p>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12">
          <h1 class="text-white invoice-heading">
            Invoice
            <span
              @click="print"
              class="button mt-5 float-right hide-for-print">
              Print
            </span>
          </h1>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-2">
          <p class="text-white">
            Invoice to:<br />
            <span class="customer-name">
              {{ order.customer.first_name }} {{ order.customer.last_name }}<br />
            </span>
            <span v-if="order.invoice_address.line_one">
              {{ order.invoice_address.line_one }}<br />
            </span>
            <span v-if="order.invoice_address.line_two">
              {{ order.invoice_address.line_two }}<br />
            </span>
            <span v-if="order.invoice_address.town">
              {{ order.invoice_address.town }}<br />
            </span>
            <span v-if="order.invoice_address.postcode">
              {{ order.invoice_address.postcode }}
            </span>
          </p>
        </div>
        <div class="cell small-12 medium-2">
          <p class="text-white">
            Deliver to:<br />
            <span class="customer-name">
              {{ order.customer.first_name }} {{ order.customer.last_name }}<br />
            </span>
            <span v-if="order.invoice_address.line_one">
              {{ order.delivery_address.line_one }}<br />
            </span>
            <span v-if="order.invoice_address.line_two">
              {{ order.delivery_address.line_two }}<br />
            </span>
            <span v-if="order.invoice_address.town">
              {{ order.delivery_address.town }}<br />
            </span>
            <span v-if="order.invoice_address.postcode">
              {{ order.delivery_address.postcode }}
            </span>
          </p>
        </div>
        <div class="cell small-12 medium-8">
          <p class="text-white float-right">
            Date:<br />
            {{ order.created | prettyDate }}<br />
            No:<br />
            CC_{{ order.id }}
          </p>
        </div>
      </div>
    </div>
    <div class="relative pa-10">
      <div class="invoice-header">
        <div>Item Description</div>
        <div>Type</div>
        <div>Position</div>
        <div>AW Size</div>
        <div>Size</div>
        <div>Unit Price</div>
        <div>Quantity</div>
        <div>Price</div>
      </div>
      <div class="grid-x grid-padding-x pt-4">
        <div class="cell small-12">
          <table class="invoice-table">
            <tbody>
              <tr
                v-for="(garment, index) in garments" :key="index">
                <td class="name-cell">
                  {{ garment.garment.code }}
                  {{ garment.garment.name }}
                  {{ garment.garment.colour }}
                </td>
                <td>
                  <span v-for="(design, index) in garment.design" :key="index">
                    {{ design.type}}
                  </span>
                </td>
                <td>
                  <span v-for="(design, index) in garment.design" :key="index">
                    {{ design.position }}
                  </span>
                </td>
                <td>
                  <span v-for="(design, index) in garment.design" :key="index">
                    {{ design.size }}cm
                  </span>
                </td>
                <td>{{ garment.garment.size }}</td>
                <td>{{ garment.garment.price | priceInPounds | currency }}</td>
                <td>{{ garment.garment.quantity }}</td>
                <td>
                  {{ (garment.garment.quantity * garment.garment.price)
                  | priceInPounds | currency }}
                </td>
              </tr>
            </tbody>
          </table>
          <table class="invoice-table">
            <thead>
              <tr class="heavy-border">
                <th style="width:40%">Due Date</th>
                <th>Total Ex Vat</th>
                <th>VAT 20%</th>
                <th class="text-right">Due Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width:40%" class="amount">{{ order.due_date | prettyDate }}</td>
                <td class="amount">{{ subTotalPrice | priceInPounds | currency }}</td>
                <td class="amount">{{ vat | priceInPounds | currency }}</td>
                <td class="text-right due-amount">{{ totalPrice | priceInPounds | currency }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'Invoice',
  data() {
    return {
      order: {
        customer: {},
        invoice_address: {},
        delivery_address: {},
      },
      garments: [],
      totalGarments: 0,
      subTotalPrice: 0,
      vat: 0,
      totalPrice: 0,
    };
  },
  methods: {
    print() {
      window.print();
    },
    getOrder() {
      axios.get(`/orders/getOrder/${this.$route.params.id}.json`)
        .then((response) => {
          this.order = response.data.order;
          this.garments = response.data.garments;
          this.totalGarments = response.data.totalGarments;
          this.subTotalPrice = response.data.subTotalPrice;
          this.vat = response.data.vat;
          this.totalPrice = response.data.totalPrice;
        });
    },
  },
  mounted() {
    this.getOrder();
  },
};
</script>

<style scoped>
.invoice {
  margin: 5% 5% 0 5%;
  width: 90%;
}
.customer-name {
  text-transform: uppercase;
  font-size: 18px;
}
.invoice-header {
  position: absolute;
  top: -28px;
  left: 0;
  width: 90%;
  margin-left: 5%;
  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 25px;
}

.invoice-heading {
  font-family: 'rennerbook';
  font-size: 40px;
}

.invoice-header div {
  float: left;
  width: 10%;
}
.invoice-header div:first-child {
  width: 30%;
  text-align: center;
}

.invoice-table tr {
  background: transparent;
  border-bottom: 2px solid #f1f1f1;
}
.invoice-table tr.heavy-border {
  border-bottom: 3px solid #333;
}
.invoice-table thead {
  background: transparent;
  border: none;
}
.invoice-table tbody {
  border: none;
}
.invoice-table td {
  width: 10%;
  font-size: 12px;
}
.invoice-table td.name-cell {
  width: 30%;
  font-size: 14px;
}
.amount {
  font-size: 16px;
}
.due-amount {
  color: #1dd699;
  font-size: 20px;
}
</style>
